<template>
	<div>
		<!--意见列表-->
		<el-dialog title="查看预审查意见" :visible.sync="showyy" width="85%">
			<el-button type="primary" @click="muludaochu" size="small">导出Excel</el-button>
			<div style="height: 60vh;overflow-y: auto;">
				<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true" :showcz="false"
					:showpages="false">

				</MyTable>
			</div>
		</el-dialog>

	</div>

</template>

<script>
	import XLSX from 'xlsx-js-style';
	export default {
		data() {
			return {
				tableHeader: [{
						name: '提案号',
						prop: 'code',
						width: '80'
					},
					{
						name: '标题',
						prop: 'proposa_name',
						width: '300'
					},
					{
						name: '提案人',
						prop: 'taz',
						width: '120'
					},
					{
						name: '状态',
						prop: 'type',
						width: '120',
						type: 'tags'
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: '150'
					},
					{
						name: '预审查人',
						prop: 'user_name',
						width: '120'
					},
					{
						name: '预审查意见',
						prop: 'sc_type',
						width: '150',
						type: 'yscyj'
					},
					{
						name: '理由',
						prop: 'scyj',
						width: ''
					}
				],
				tableData: [],
				showyy: false,
				showflag: false,
				showtitle: '',
				htmldiv: '',
				cntime: '',
				tianname: '', //提案名称
			}
		},
		methods: {
			//导出excel----------------------------------------------------------------------------------------------------------------------
			muludaochu() {
				// 创建工作簿
				let wb = XLSX.utils.book_new();

				// 创建工作表数据
				let ws_data = [
					[this.tianname], // 第一行，后面将合并单元格
					['提案号', '标题', '提案人', '状态', '提交时间', '预审查人', '预审查意见', '理由'] // 第二行，列头
				];

				this.tableData.forEach(participant => {
					let row = [participant.code, participant.proposa_name, participant.taz,'接收待立案', participant.addtime, participant.user_name,participant.sc_type, participant.scyj];
					ws_data.push(row);
				});

				// 创建带有数据的工作表
				let ws = XLSX.utils.aoa_to_sheet(ws_data);

				// 设置工作表的列宽
				ws['!cols'] = [{ wch: 10 }, // 提案号列宽
					  { wch: 50 }, // 标题列宽
					  { wch: 10 }, // 
					  { wch: 18 }, // 
					  { wch: 25 }, // 
					  { wch: 15 }, // 
					  { wch: 15 }, // 
					  { wch: 80 }, // 
				];

				// 第一行标题样式
				const titleStyle = {
					font: {
						name: '宋体',
						sz: 18,
						bold: true
					},
					alignment: {
						horizontal: 'center',
						vertical: 'center'
					},
				};

				// 第二行列头样式
				const headerStyle = {
					font: {
						name: '宋体',
						sz: 15,
						bold: true
					},
					alignment: {
						horizontal: 'center',
						vertical: 'center'
					},
					border: {
						top: {
							style: 'thin'
						},
						bottom: {
							style: 'thin'
						},
						left: {
							style: 'thin'
						},
						right: {
							style: 'thin'
						}
					}
				};

				// 数据样式
				const dataStyle = {
					font: {
						name: '宋体',
						sz: 14
					},
					alignment: {
						horizontal: 'center',
						vertical: 'center'
					},
					border: {
						top: {
							style: 'thin'
						},
						bottom: {
							style: 'thin'
						},
						left: {
							style: 'thin'
						},
						right: {
							style: 'thin'
						}
					}
				};

				// 应用标题样式并合并第一行的单元格
				XLSX.utils.sheet_add_aoa(ws, [
					[this.tianname]
				], {
					origin: 'A1'
				});
				ws['A1'].s = titleStyle;
				ws['!merges'] = [XLSX.utils.decode_range('A1:H1')];

				// 应用列头样式
				['A2', 'B2', 'C2', 'D2', 'E2', 'F2','G2', 'H2'].forEach(key => {
					ws[key].s = headerStyle;
				});

				// 应用数据样式
				for (let R = 3; R <= ws_data.length; R++) {
					for (let C = 65; C <= 72; C++) { // ASCII 码 65 是 'A', 71 是 'H'
						let cell_ref = String.fromCharCode(C) + R; // 生成单元格引用，例如 'A3'
						console.log(cell_ref)
						ws[cell_ref].s = dataStyle;
					}
				}

				// 把工作表添加到工作簿
				XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

				// 定义导出的文件名
				let exportFileName = this.tianname + '.xlsx';

				// 导出 Excel 文件
				XLSX.writeFile(wb, exportFileName, {
					bookType: 'xlsx',
					type: 'binary'
				});

			},
			print() {
				this.$printPage(this.$refs.print)
			},
			showtext(item, i) { //i=1 查看答复，i=2 查看承诺
				this.htmldiv = i == 1 ? item.dfh : item.blcl
				this.showtitle = i == 1 ? '查看答复' : '查看承诺'
				console.log(this.showtitle)
				this.showflag = true
				this.cntime = item.blcltime
			},
			lookyy(item) {
				this.$alert('异议：' + item.yyly, '查看异议', {
					confirmButtonText: '确定',
					callback: action => {

					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

</style>